@import "../../assets/styles/variables";

.select-wrapper {
  position: relative;
  &:focus-within {
    .label {
      font-size: 12px;
      transform: translateY(-25px);
    }
  }
  .label {
    font-family: $manrope-light;
    color: $coral;
    position: absolute;
    bottom: 14px;
    transition: top, .4s;
    &.label-disabled {
      opacity: .5;
    }
    &.label-error {
      color: $danger
    }
  }
}
