@import "../../assets/styles/variables";

.requestInfo {
  .titleWrapper {
    margin-bottom: 0 !important;
    .title {
      font-size: 30px;
    }
  }
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}

.selectWrapper {
  min-width: 200px;
  max-width: 250px;
}

#insurancePartnershipCard {
  .orgName {
    color: $primary;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 41px;
    margin-bottom: 30px;
  }
}
