.container {
  margin-left: 15px;
  box-shadow: 0 8px 20px 0 rgba(68,71,91,0.12);
  padding: 12px;
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  background: white;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;

.head{
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  .headText {
    flex: 1 1 0px;
    color: rgb(25, 34, 51);
    font-size: 14px;
    font-weight: 700;
    overflow-wrap: break-word;
  }
}


.address {
  align-self: stretch;
  color: rgb(99, 111, 119);
  font-size: 12px;
  font-weight: 500;
  overflow-wrap: break-word;
}






}


