@import 'variables';

//margin classes
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-0 {margin: 0!important;}
.ml-0 {margin-left: 0!important;}
.mr-0 {margin-right: 0!important;}
.mt-0 {margin-top: 0!important;}
.mb-0 {margin-bottom: 0!important;}

.m-5 {margin: 5px!important;}
.ml-5 {margin-left: 5px!important;}
.mr-5 {margin-right: 5px!important;}
.mt-5 {margin-top: 5px!important;}
.mb-5 {margin-bottom: 5px!important;}

.m-10 {margin: 10px!important;}
.ml-10 {margin-left: 10px!important;}
.mr-10 {margin-right: 10px!important;}
.mt-10 {margin-top: 10px!important;}
.mb-10 {margin-bottom: 10px!important;}

.m-15 {margin: 15px!important;}
.ml-15 {margin-left: 15px!important;}
.mr-15 {margin-right: 15px!important;}
.mt-15 {margin-top: 15px!important;}
.mb-15 {margin-bottom: 15px!important;}

.m-20 {margin: 20px!important;}
.ml-20 {margin-left: 20px!important;}
.mr-20 {margin-right: 20px!important;}
.mt-20 {margin-top: 20px!important;}
.mb-20 {margin-bottom: 20px!important;}

.m-25 {margin: 25px!important;}
.ml-25 {margin-left: 25px!important;}
.mr-25 {margin-right: 25px!important;}
.mt-25 {margin-top: 25px!important;}
.mb-25 {margin-bottom: 25px!important;}

.mr-30 {margin-right: 30px!important;}
.mb-30 {margin-bottom: 30px!important;}
.mt-30 {margin-top: 30px!important;}
.ml-30 {margin-left: 30px!important;}

.mt-40 {margin-top: 40px!important;}
.mb-40 {margin-bottom: 40px!important;}
.mr-40 {margin-right: 40px!important;}
.ml-40 {margin-left: 40px!important;}


.mt-50 {margin-top: 50px!important;}
.mb-50 {margin-bottom: 50px!important;}
.mr-50 {margin-right: 50px!important;}
.ml-50 {margin-left: 50px!important;}

@media (max-width: $break-middle) {
  .m-md-0 {margin: 0!important;}
  .mt-md-0 {margin-top: 0!important;}
  .mr-md-0 {margin-right: 0!important;}
  .ml-md-0 {margin-left: 0!important;}
  .mb-md-0 {margin-bottom: 0!important;}

  .m-md-5 {margin: 5px!important;}
  .mt-md-5 {margin-top: 5px!important;}
  .mr-md-5 {margin-right: 5px!important;}
  .ml-md-5 {margin-left: 5px!important;}
  .mb-md-5 {margin-bottom: 5px!important;}

  .m-md-10 {margin: 10px!important;}
  .mt-md-10 {margin-top: 10px!important;}
  .mr-md-10 {margin-right: 10px!important;}
  .ml-md-10 {margin-left: 10px!important;}
  .mb-md-10 {margin-bottom: 10px!important;}

  .m-md-15 {margin: 15px!important;}
  .mt-md-15 {margin-top: 15px!important;}
  .mr-md-15 {margin-right: 15px!important;}
  .ml-md-15 {margin-left: 15px!important;}
  .mb-md-15 {margin-bottom: 15px!important;}

  .m-md-20 {margin: 20px!important;}
  .mt-md-20 {margin-top: 20px!important;}
  .mr-md-20 {margin-right: 20px!important;}
  .ml-md-20 {margin-left: 20px!important;}
  .mb-md-20 {margin-bottom: 20px!important;}

  .mt-md-40 {margin-top: 40px!important;}

}
