.container {
  width: 100%;
  height: 160px;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid rgb(231, 231, 231);
  display: inline-flex;

  .select {
    width: 100%;
  }

  .head {
    align-self: stretch;
    height: 48px;
    padding: 8px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    .headWrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      .title {
        flex: 1 1 0px;
        color: rgb(25, 34, 51);
        font-size: 16px;
        font-weight: 700;
        overflow-wrap: break-word;
      }
    }
  }

  .body {
    align-self: stretch;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    display: flex;
  }
}
