@import "../../assets/styles/variables";
#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 900;
  display: flex;
  justify-content: center;
}
#modal-content {
  position: relative;
  z-index: 850;
  width: 568px;
  max-width: 100%;
  height: max-content;
  border-radius: 16px;
  border: 1px solid $light-grey;
  background-color: $white;
  box-shadow: 0 12px 34px 0 rgba(0,0,0,0.25);
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  margin: auto;
  &.small {
    padding: 20px 30px 30px 30px;
  }
  .modal-title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 35px;
  }
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: $grey;
    cursor: pointer;
  }
  @media (max-width: $break-middle) {
    width: 96%;
    padding: 20px 10px 30px 10px;
    &.small {
      padding: 30px;
    }
    .modal-divider {
      margin: 20px -10px 30px -10px
    }
  }
  .text {
    line-height: 24px;
  }
  .mail {
    text-decoration: underline;
  }
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 800;
  background-color: #333;
  opacity: .5;
  top: 0;
  left: 0;
}

