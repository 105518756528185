@import "../../assets/styles/variables";

.notification-wrapper {
  position: relative;
  cursor: pointer;
  margin: 0 120px 0 30px;
  .icon {
    fill: $primary;
    &.active {fill: $dark-blue}
  }
  .amount {
    position: absolute;
    top: -1px;
    right: -4px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: $font-xs;
    width: 16px;
    height: 16px;
    background-image: $orange-gradient;
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  padding: 20px 15px;
  color: $primary;
  .title {
    font-size: $font-l;
    color: $primary;
  }
  .mark-all {
    cursor: pointer
  }
  .filter {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #202020;
  }
  .filter-item {
    padding-bottom: 20px;
    &:last-child {padding-bottom: 0}
  }
}

.notifications-content {
  overflow-y: auto;
  height: calc(100%  - 65px);
}

.notifications-tooltip {
  width: 560px;
  height: 80%;
  overflow-y: hidden;
  border-radius: 16px!important;
  border: 1px solid $light-grey!important;
  background-color: $white!important;
  box-shadow: 0 12px 34px 0 rgba(0,0,0,0.25);
  opacity: 1!important;
  padding: 0!important;
  color: $primary
}
