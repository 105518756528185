@mixin text-dots($width: initial) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: $width;
}

@mixin horizontal-line($width: 100%, $color: $light-grey, $height: 1px) {
  width: $width;
  background-color: $color;
  height: $height;
}
