@import "../../assets/styles/variables";

#dispatchersList {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .item {
    &:last-child {
      width: 7%;
      flex-shrink: 0;
      text-align: center;
      padding: 0 15px;
    }
    .editBtn {
      flex-shrink: 0;
    }
  }
}

.panelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    padding: 50px 0;
    font-size: 30px;
    font-family: $manrope-light;
  }
}

.panelFooter {
  justify-content: flex-end;
}

#dispatcherForm {
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    .input {
      flex-basis: 167px;
    }
  }
  .avatarWrapper {
    position: relative;
    display: inline-flex;
    &:hover {
      .removeIcon {
        display: block;
      }
    }
    .avatar {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 12px;
      margin-bottom: 10px;
    }
    .removeIcon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 3px;
      border-radius: 50%;
      background-color: $white;
      display: none;
      &:after {
        content: "";
        background: url("../../assets/svgs/close.svg") no-repeat center;
        background-size: cover;
        width: 18px;
        height: 18px;
        display: flex;
      }
    }
  }
  .imgUploadWrapper {
    display: flex;
    align-items: center;
    cursor: pointer
  }
}
