@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

#patientInfo {
  position: relative;

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .addressInfo p {
      @include text-dots();
      max-width: 300px;
    }

    .city p, .subway p {
      @include text-dots();
      max-width: 100px;
    }
  }

  .divider {
    @include horizontal-line()
  }

  .editIcon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
  }
}

#brigadeFormWrapper {
  display: flex;
  flex-direction: column;

  .form {
    position: relative;
    padding: 20px;
    background-color: $column-bg;
    border-radius: 16px;
    margin-bottom: 25px;
  }

  .removeIcon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  }

  .brigadeFormTitle {
    font-family: $manrope-medium;
    font-size: $font-l;
    color: $dark-blue;
    margin-bottom: 40px;
  }

  .input {
    flex-basis: 240px
  }

  .select-wrapper {
    flex-basis: 240px
  }

  #input-wrapper {
    flex-basis: 240px
  }
}

#offersContainer {
  border-bottom: 1px solid $light-grey;
  margin: 0 -40px 25px -40px;
  padding: 0 40px 10px 40px;

  .offerWrapper {
    padding: 12px 25px 12px 15px;
    background-color: $column-bg;
    border-radius: 10px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textMain {
    font-family: $manrope-bold;
    margin-bottom: 5px;
    line-height: 19px;
  }

  .text {
    font-family: $manrope-light;
    font-size: $font-s;
    line-height: 17px;

    &:last-child {
      margin-left: 15px
    }
    &:first-child {
      margin-right: 15px
    }
  }

  .actionBox {
    display: flex;
    align-items: center;

    .loader {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    .trash {
      cursor: pointer
    }
  }
}

#brigadeInfo {
  position: relative;
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  .trashIcon, .editIcon {
    position: absolute;
    top: 0;
    right: 50px;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-basis: 167px
    }
  }
}

#delayForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input {
    max-width: 260px;
    margin-bottom: 40px;
  }

  .btn {
    width: 100%;
  }
}

#replaceBrigadeForm {
  .wrapper {
    display: flex;
    justify-content: space-between;

    .input {
      flex-basis: 260px
    }
  }
}

#diagnoseInfo {
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  .wrapper {
    .info p {
      font-size: $font-l;
      font-family: $manrope-regular;
    }
  }
}

#hospitalInfo {
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wrapper {
      .info p {
        font-size: $font-l;
        font-family: $manrope-regular;
      }
    }
  }
}

.actionsTooltip {
  background-color: $white !important;
  border-radius: 16px !important;
  border: 1px solid $light-grey;
  padding: 20px 40px 40px 40px !important;
  color: $primary !important;
  font-family: $manrope-regular;
  opacity: 1 !important;
  box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.25);

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    margin: 0 -40px 30px -40px;
    padding: 0 40px 15px 40px;

    .actionsTitle {
      font-size: 20px;
      font-family: $manrope-regular;
      flex-basis: 260px;

      &:last-child {
        //margin-left: 60px
      }
    }
  }

  .list {
    flex-basis: 260px;

    &:last-child {
      //margin-left: 60px;
    }

    .item {
      font-size: $font-l;
      cursor: pointer;
      margin-bottom: 10px;
      line-height: 20px;
      padding: 10px 15px;
      margin-left: -15px;
      border-radius: 8px;

      &:last-child {
        margin-bottom: 0
      }

      &.active {
        background-color: $column-bg;
      }

      &.disabled {
        opacity: .5
      }

      @include text-dots(260px)
    }
  }
}

.cancelOfferModal {
  .modalText {
    color: $grey;
    font-size: $font-l;
  }

  .modalBtn {
    width: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: $font-l;
  font-family: $manrope-semi-bold;

  svg {
    fill: $dark-blue;
    margin-right: 10px
  }

  margin: 0 0 20px -6px;
}
