@import "../../assets/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  //min-width: 190px;

}

.title {
  font-size: $font-s;
  color: $coral;
  padding-bottom: 5px;
  line-height: 17px;
}

.text {
  color: $primary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

