#hospitals-list {
  .item:first-child, .item:last-child {
    width: 90px;
    padding: 0 10px;
  }
  .title {
    white-space: normal;
  }
  .search {
    flex-basis: 300px;
  }
}
