@import "../../assets/styles/variables";

.request-container {
  flex: 1 1 auto;
  //flex-basis: 500px;
  border-radius: 8px;
  height: calc(100vh - 110px);
  background-color: $column-bg;
  margin: 0 10px;
  padding: 20px 15px;
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 15px;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      color: $dark-blue;
      font-size: $font-l;
    }

  }
  .body {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none
    }
  }
}


.request-containerSmall {
  height: calc(100vh - 180px);
}
