@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
.container {
  width: 100%;
  height: 333px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;

  .input {
    width: 100% !important;
  }

  .select {
    width: 220px;
  }
  .head {
    align-self: stretch;
    height: 48px;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    display: flex;
    .headWrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;

      svg {
        fill: $dark-blue;
      }

      .text {
        flex: 1 1 0px;
        color: rgb(25, 34, 51);
        font-size: 16px;
        font-weight: 700;
        overflow-wrap: break-word;
      }
    }
  }

  .entity {
    align-self: stretch;
    height: 112px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: flex;

    .wrapper {
      align-self: stretch;
      justify-content: flex-start;
      gap: 28px;
      display: inline-flex;
    }
  }


  .individual {
    align-self: stretch;
    height: 112px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    display: flex;
  }

}
