html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none;
  box-sizing: border-box;
}

body {
  line-height: 1;
  font-family: $manrope-regular;
  font-size: 14px;
  color: $primary;
  overflow: hidden;
  &.admin-body,
  &.scrollable{
    overflow: auto;
  }
}
.react-time-picker__wrapper {
  border: none !important;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

h1, h2, h3, h4, h5, h6 {font-family: $manrope-regular}

h1 {font-size: 40px}
h2 {font-size: 30px}
h3 {font-size: 20px}

strong {
  font-weight: bold;
}

input {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  max-width: 100%;
}

a {
  color: unset;
  text-decoration: none;

  &:visited {
    color: unset
  }

  &:hover {
    text-decoration: none;
  }
}

@media (max-width: $break-middle) {
  body {
    padding-top: 50px
  }
}


