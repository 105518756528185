@import "../../assets/styles/variables";

.avatarWrapper {
  position: relative;
  display: inline-flex;
  &:hover {
    .removeIcon {
      display: block;
    }
  }
  .avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 10px;
  }
  .removeIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px;
    border-radius: 50%;
    background-color: $white;
    display: none;
    &:after {
      content: "";
      background: url("../../assets/svgs/close.svg") no-repeat center;
      background-size: cover;
      width: 18px;
      height: 18px;
      display: flex;
    }
  }
}
.imgUploadWrapper {
  display: flex;
  align-items: center;
  cursor: pointer
}
