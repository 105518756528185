@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.divider {
  @include horizontal-line()
}

#patient-info {
  position: relative;
  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .addressInfo p{
      @include text-dots();
      max-width: 300px;
    }
  }
}

#new-request {

  #panel-popover {
    padding-bottom: 0;
  }

  .add-btn {
    cursor: pointer;
    font-size: $font-l;
    display: flex;
    align-items: center;
    color: $grey;
    padding-left: 20px;
    padding-bottom: 30px;
    svg {
      margin-right: 15px;
    }
  }

  .send-btn {
    margin-top: auto
  }

  .footer {
    justify-content: center;

    .offer-button {
      width: 100%;

      button {
        margin: auto
      }
    }
  }
  .offer-form-footer {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    padding: 30px 0 25px 0;
  }
}

#accept-request {
  .accept-footer {
    justify-content: space-between;

    .btn-wrapper {
      button {
        margin: auto
      }
    }
  }
}

#assign-brigade-request {
  .footer {
    justify-content: center;

    .btn-wrapper {
      width: 100%;
    }

    button {
      margin: auto
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    #input-container {
      flex-basis: 260px;
    }
  }

  .action-box {
    display: flex;
    justify-content: space-between;

    .btn-wrapper {
      flex-basis: 264px;

      button {
        margin: auto
      }
    }
  }
}

#on-way-request {
  &.negative-margin {margin-top: -24px}
  .change-er-info {
    background-color: $column-bg;
    padding: 15px 40px 30px 40px;
    margin: 0 -40px;
    .title {
      font-size: $font-l;
      margin-bottom: 15px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .btn-wrapper {
      width: 100%;
    }
  }
}

#arrived-request {
  .footer {
    .btn-wrapper {
      flex-basis: 156px;

      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  .diagnose-popover {
    overflow-y: visible;
  }
}

#hospitalization-request {
  .wrapper {
    background-color: $column-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 30px;

    .info-wrapper {
      p {
        font-family: $manrope-semi-bold;
        line-height: 19px;
        margin-bottom: 5px;
      }

      span {
        font-size: $font-s;
        font-family: $manrope-light;
        color: $dark-blue;
        cursor: pointer
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.issue-btn {
  position: relative;
  width: 100%;
  padding: 11px 10px;
  cursor: pointer;
  font-family: $manrope-semi-bold;
  border: 1px solid $mid-grey;
  border-radius: 8px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    position: absolute;
    left: 10px;
  }
}

#issue-menu {
  cursor: pointer;

  p {
    font-size: $font-l;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.comments-footer {
  position: absolute !important;
  width: 100% !important;
  border-bottom: unset;
  &.isSentry {
    width: 26% !important;
    margin: 0 !important;
  }
  #input-container {
    width: 100%;

    .input-wrapper {
      border-bottom: unset;
    }
  }
  .upload-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    input {display: none}
  }
}

.decline-request-modal {
  .text {
    color: $grey;
    font-size: $font-l;
  }

  .btn-wrapper {
    width: 100%;
  }
}

#closed-request, #hospitalization-request {
  .footer {
    justify-content: space-evenly;

    .width-40 {
      width: 40%;
    }
    .offer-button {
      width: 100%;

      button {
        margin: auto
      }
    }
  }
}
