@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.temp03-table-container {
  margin-top: 20px;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .table-head {
    background-color: $column-bg;
    border: 1px solid $light-grey;
    border-bottom: none;
    border-radius: 16px 16px 0 0;
    .equal-item {
      border: none !important;
    }
    .equal-item, .item {
      width: 100%;
      border-top: unset;
      min-height: 55px;
      white-space: nowrap;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 19px;
        width: 100%;
      }
    }
  }

  .no-top-border {
    border-top: none !important;
  }

  .table-body {
    border: 0.5px solid $light-grey;
    .item, .equal-item {
      cursor: pointer;
      width: 100%;
      border-right: 0.5px solid $light-grey;
      border-top: unset;
      min-height: 55px;
      white-space: nowrap;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 19px;
        width: 100%;
      }
    }
  }

  .row {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 0.5px solid $light-grey;
    &:last-child{
      border-bottom: none;
    }
  }

  .equal-item {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px;
    align-items: center;
    @include text-dots();
    width: 100%;
    &:last-child {
      border: none;
    }
  }

  .item {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    align-items: center;
    @include text-dots();
    &:last-child {border: none}
    a {
      width: 100%;
      line-height: 55px;
    }
  }
}

.left-table-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.left-table-container {
  display: inline-flex;
  flex-flow: column nowrap;
  min-width: 100%;
  height: 100%;
}

.fit-to-screen {
  overflow: scroll;
  height: calc(100vh - 205px);
}
