.container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(231, 231, 231);
  border-left: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  .select {
    width: 220px;
  }
  .head {
    align-self: stretch;
    height: 48px;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    display: flex;

    .headWrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      .title {
        flex: 1 1 0px;
        color: rgb(25, 34, 51);
        font-size: 16px;
        font-weight: 700;
        overflow-wrap: break-word;
      }
    }
  }

  .body {
    align-self: stretch;
    height: 448px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: flex;
    .input{
      width: 100% !important
    }

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .addressInfo p {
        max-width: 300px;
      }

      .city p, .subway p {
        max-width: 100px;
      }
    }

    .timePicker {
      width: 100%;
      margin-top: 7px;
      border: none;
    }

    .bodyHeadWrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      display: inline-flex;


    }
  }
}
