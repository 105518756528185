@import "../../assets/styles/variables";

@mixin button {
  border: unset;
  font-family: $manrope-semi-bold;
  font-size: $font-main;
  line-height: 20px;
  margin: auto;
  background-color: unset;
  color: $primary;
  cursor: pointer;
  padding: 0;
}

@mixin root-outlined {
  border-radius: 8px;
  background-color: transparent;
}

@mixin root-contained {
  border-radius: 12px;
}

@mixin btn-outlined($color) {
  border: 1px solid $color;
  padding: 9px 18px;
  button {
    color: $primary
  }
}

@mixin btn-contained($background-color) {
  background: linear-gradient(42.46deg, #376ECD 0%, #4CC3CD 100%);
  border: unset;
  button {
    color: $white
  }
}

button {
  @include button
}

.btn-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 18px;
  width: max-content;
  height: min-content;

  svg {
    height: unset;
  }
}

.full-width {width: 100%}

.half-width {width: 50% !important;}

.default {
  height: min-content;
  padding: 0;

  button {
    @include button
  }

  color: $primary
}

.small {
  padding: 6px 15px;
  border-radius: 8px!important;
  button {
    font-size: $font-s;
  }
}

.outlined {
  @include root-outlined;
  @include btn-outlined($mid-grey);

  &:hover {
    &:before {
      content: '';
      background-color: $mid-grey;
      opacity: .1;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 12px;
      display: flex;
    }
  }

  &:active {
    &:before {
      content: '';
      background-color: $mid-grey;
      opacity: .2;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 12px;
      display: flex;
    }
  }
}


.contained {
  @include root-contained;
  @include btn-contained($primary);

  &:hover {
    &:before {
      content: '';
      background-color: $white;
      opacity: .1;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 12px;
      display: flex;
    }
  }

  &:active {
    &:before {
      content: '';
      background-color: $black;
      opacity: .1;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 12px;
      display: flex;
    }
  }

  &.secondary {
    background-image: unset;
    background-color: $dark-blue;
  }
}

.simple {
  border: unset;
  button {
    color: $grey
  }
}

.disabled {
  cursor: not-allowed;
  background: $mid-grey;

  * {
    cursor: not-allowed
  }

  button {
    cursor: not-allowed
  }
}

.danger {
  color: $danger;

  button {
    color: $danger
  }

  svg {
    fill: $danger
  }
}

