@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.notification {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  &:hover {cursor: pointer}
  &.new {background-color: $column-bg}

  .icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    margin-top: 10px;
    flex-shrink: 0;
    &-offer {background-image: $blue-gradient;}
  }

  .content {
    flex-grow: 1;
    margin: 0 -20px 0 20px;
    color: $primary;
    &-title {
      font-family: $manrope-medium;
      margin-bottom: 10px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding: 0 20px 10px 0;
  }

  .data {
    font-size: $font-s;
    .title {
      color: $coral;
      line-height: 17px;
      margin-bottom: 5px;
    }
    .text {
      font-family: $manrope-regular;
    }
  }
  .time {
    align-self: flex-end;
    color: $coral;
  }
}
