@import "../../assets/styles/variables";

#no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin: 200px 0 30px 0;
    width: 200px;
  }
  .text {
    font-size: $font-l;
    color: $grey;
    text-align: center;
    line-height: 22px;
  }
}
