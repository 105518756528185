@import "../../assets/styles/variables";

.search {
  .tabs {
    display: flex;
    p {
      display: flex;
      font-size: $font-s;
      align-items: center;
      cursor: pointer;
      padding: 0 15px 10px;
      &.active {
        font-family: $manrope-bold;
        border-bottom: 2px solid $dark-blue;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    .caseCount {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      background-color: $dark-blue;
      color: $white;
      width: 15px;
      height: 15px;
      font-family: $manrope-semi-bold;
      font-size: $font-xs;
      border-radius: 3px;
    }
  }

  .table {
    margin-top: 0
  }

  .tableHead {
    border-radius: unset;
    border: unset
  }

  .item {
    &:first-child {width: 50%}
    &:nth-child(4) {width: 35%}
    &:nth-child(5) {width: 50%}
    &:nth-child(7) {width: 50%}
  }
}
