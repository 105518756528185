@import "../../assets/styles/variables";

#comment-panel {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 -40px;
  padding-bottom: 80px;
  .comment-wrapper {
    border-bottom: 1px solid $light-grey;
    padding: 20px 40px;
    &.new {
      background-color: $column-bg;
    }
    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: $font-s;
      .sender {
        font-family: $manrope-semi-bold;
      }
      .date {
        color: $coral
      }
    }
    .message {
      font-family: $manrope-light;
      line-height: 19px;
      letter-spacing: 0;
      .attachment {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-right: 15px;
        }
      }
    }
  }
}

#history-panel {
  overflow-y: auto;
  overflow-x: hidden;
  .text-wrapper {
    border-bottom: 1px solid $light-grey;
    padding: 15px 12px;
    margin: 5px;
    .article-block {
      display: flex;
      margin-bottom: 15px;
      overflow-x: auto;
      white-space: nowrap;
      margin-right: -40px;
      visibility: visible;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none
      }
      article {
        display: flex;
        flex-basis: 200px;
        flex-shrink: 0;
        height: 140px;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid $light-grey;
        padding: 15px;
        margin-left: 15px;
        &.selected {
          background-color: $column-bg;
          border-color: transparent;
        }
        &:first-child {margin-left: 0}
        .title {
          font-family: $manrope-semi-bold;
          margin-bottom: 5px;
        }
        .selected {
          font-size: $font-s;
          font-family: $manrope-semi-bold;
          color: $dark-blue;
        }
        .footer {
          .text {
            font-size: $font-s;
            font-family: $manrope-light;
            margin-bottom: 5px;
            &:last-child {margin-bottom: 0}
          }
        }
      }
    }
    p {
      line-height: 18px;
    }
    .text {
      font-family: $manrope-light;
      margin-bottom: 10px;
      line-height: 20px;
    }
    .date, .priority {
      font-family: $manrope-light;
      font-size: $font-s;
      color: $coral
    }
  }
}
