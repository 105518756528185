@import "../../assets/styles/variables";

#bigPanel-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 700;
  justify-content: flex-end;
  display: flex;
}

#bigPanel-content {
  position: relative;
  z-index: 850;
  width: 90%;
  //width: 620px;
  max-width: 100%;
  height: 100vh;
  background-color: #fff;
  //padding: 0 40px;
  display: flex;
  flex-direction: column;
  transition: left, .6s;
  transform: translateX(100%);
  overflow: visible;

  &.open {
    transform: translateX(0);
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: $light-grey;
    cursor: pointer;
  }

  @media (max-width: $break-middle) {
    width: 96%;
    padding: 20px 10px 30px 10px;
    .modal-divider {
      margin: 20px -10px 30px -10px
    }
  }
}

#bigPanel-header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid $light-grey;
  background-color: $white;
  z-index: 1;
  padding: 0 40px 0 40px;
  //margin: 0 -40px 24px -40px
}

#rightPanel-footer {
  position: sticky;
  width: 100%;
  display: flex;
  background-color: $white;
  bottom: 0;
  padding: 20px 40px 20px 40px;
  border: 1px solid $light-grey;
  //margin: 0 -40px;
  z-index: 1;
}

#panel-popover {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 600px;
  min-height: 250px;
  border-radius: 24px 24px 0 0;
  background-color: $white;
  z-index: 1100;
  padding: 0 40px 30px 40px;
  transition: bottom, .6s;
  transform: translatey(100%);
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none
  }
  .title-wrapper {
    position: sticky;
    top: 0;
    padding: 30px 0 25px 0;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    z-index: 1;
    .title {
      font-size: 20px;
      font-family: $manrope-medium;
    }
    .close-icon {
      fill: $primary;
      cursor: pointer;
      right: 0;
    }
  }
  &.open {
    transform: translateY(0);
  }
}

.popover-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: #333;
  opacity: .5;
  top: 0;
  left: 0;
}

.panel-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 800;
  background-color: #333;
  opacity: .5;
  top: 0;
  left: 0;
}
