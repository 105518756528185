.container {
  width: 100%;
  height: 100%;
  background: rgb(241, 246, 255);
  border-right: 1px solid rgb(231, 231, 231);
  border-left: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;



  .bodyContainer {
    align-self: stretch;
    flex: 1 1 0px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    height: 80%;

    .head {
      align-self: stretch;
      height: 64px;
      padding: 12px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      display: flex;
      .button {
        background: white;
      }
    }


    .bodyWrapper {
      align-self: stretch;
      height: 100%;
      //max-height: 400px;
      padding: 0px 8px;
      overflow: auto;
      color: rgb(99, 111, 119);

      .titleText{
        width: 100%;
        padding: 0 8px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        grid-gap: 8px;
        gap: 8px;
        display: inline-flex;
      }

      .bodyContentWrapper {
        width: 100%;
        padding: 12px 8px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: inline-flex;

        .bodyContent {
          align-self: stretch;
          //padding-left: 8px;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          display: inline-flex;


          .dotsWrapper {
            cursor: pointer;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            display: inline-flex;

           .disabled {
              opacity: .5
            }

          }

          .priceWrapper {
            width: 128px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            display: inline-flex;
            .priceAmount{
              align-self: stretch;
              text-align: right;
              color: rgb(25, 34, 51);
              font-size: 14px;
              font-weight: 700;
              overflow-wrap: break-word;
            }
            .priceStatus{
              align-self: stretch;
              text-align: right;
              color: rgb(231, 162, 73);
              font-size: 12px;
              font-weight: 500;
              overflow-wrap: break-word;
            }
          }
        }
      }
    }
  }

}
