@import "../../assets/styles/variables";

#input-container {
  .input-wrapper {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    border-bottom: 1px solid $mid-grey;
    padding: 0 5px 3px 0;
    height: min-content;
    cursor: text;
    position: relative;
    .label {
      color: $coral;
      font-family: $manrope-light;
      position: absolute;
      transition: top, .4s;
      &.input-disabled {
        cursor: not-allowed;
        color: #b8bdc0 !important;
      }
    }

    .full-width {
      width: 100% !important;
    }

    .placeholder-text {
      color: $coral;
      font-family: $manrope-light;
      position: absolute;
      left: 5px;
    }

    &:focus-within {
      border-color: $dark-blue;
      .label {
        font-size: 12px;
        transform: translateY(-25px);
      }
      svg {
        fill: $dark-blue
      }
    }

    &.outlined {
      border: 1px solid $mid-grey;
      background-color: $white;
      border-radius: 6px;
      padding: 10px 20px;

      &:focus-within {
        border-color: $dark-blue
      }
    }

    &.filled {
      background-color: #e8e8ed;
      border: unset;
      border-radius: 6px;
      padding: 9px 15px;

      input {
        background-color: #e8e8ed;
        font-family: $manrope-medium;
      }
    }

    &.error {
      border-color: $danger;
     > span {color: $danger}
    }

    &.disabled {
      cursor: not-allowed;
    }

    input {
      font-family: $manrope-regular;
      font-size: 14px;
      border: 0;
      outline: 0;
      background-color: transparent;
      width: 100%;

      &:disabled {
        opacity: 1;
        font-family: $manrope-light;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: $manrope-light;
        display: none;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        font-family: $manrope-light;
      }

      &:-ms-input-placeholder { /* IE 10+ */
        font-family: $manrope-light;
      }

      &:-moz-placeholder { /* Firefox 18- */
        font-family: $manrope-light;
      }
    }
  }
}


input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
