/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Manrope Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Regular'), url('../fonts/manrope-regular.woff') format('woff');
}


@font-face {
  font-family: 'Manrope Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Thin'), url('../fonts/manrope-thin.woff') format('woff');
}


@font-face {
  font-family: 'Manrope Light';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Light'), url('../fonts/manrope-light.woff') format('woff');
}


@font-face {
  font-family: 'Manrope Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Medium'), url('../fonts/manrope-medium.woff') format('woff');
}


@font-face {
  font-family: 'Manrope Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Semibold'), url('../fonts/manrope-semibold.woff') format('woff');
}


@font-face {
  font-family: 'Manrope Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Manrope Bold'), url('../fonts/manrope-bold.woff') format('woff');
}
