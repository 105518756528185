.wrapper {
  display: flex;
  width: 100%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
}

.text {
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.arrow {
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
}

.gray {
  background: linear-gradient(25.67deg, #636F77 0%, #C0C2C6 100%)
}

.blue {
  background: linear-gradient(42.46deg, #376ECD 0%, #4CC3CD 100%);
}

.green {
  background: linear-gradient(22.44deg, #3E903C 0%, #67C662 100%);
}

.orange {
  background: linear-gradient(31.73deg, #DF7C2C 0%, #F1CE6B 100%);
}
