@import "../../assets/styles/variables";

.timer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  font-size: $font-s;
  min-width: 80px;
  margin: 8px;
}

.smallTimer {
  min-width: 80px;
}

.bigTimer {
  min-width: 100px;
}

.blueTimer {
  color: #636F77;
  padding: 2px;
  background: #636F77;
}

.timerOk {
  background-color: rgba(189, 193, 204, 0.3);
  svg {fill: $grey};
  color: $coral;
  min-width: 80px;
}
.timerPassed {
  background: $orange-gradient;
  svg {fill: $white};
  color: $white;
}
