@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.container {
  width: 100%;
  display: flex;
  height: 100%;
  overflow: auto;
  border-top: 1px solid #E7E7E7;

>div {
  width: 100%;
  display: flex;
  #panel-footer {
    width: 25% !important;
  }
}

  .content {
    height: 100%;
    width: 100%;

    .bookingOffers {
      padding: 20px ;
      background-color: $column-bg;
      .text {
        svg {
          fill: $dark-blue;
          margin-right: 10px
        }
        display: flex;
        align-items: center;
        font-size: $font-l;
        font-family: $manrope-semi-bold;
      }
      span {
        font-size: 12px;
        color: #365ede;
        cursor: pointer;
      }
    }

    .wrapper {
      background-color: $column-bg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 15px;
      margin: 15px;

      .infoSWrapper {
        p {
          font-family: $manrope-semi-bold;
          line-height: 19px;
          margin-bottom: 5px;
        }

        span {
          font-size: $font-s;
          font-family: $manrope-light;
          color: $dark-blue;
          cursor: pointer
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }


    .tabContent {
      height: 100%;
      display: none;

      .buttonContainer {
        display: flex;
        height: 45px;
        padding: 0;
        background: white;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }

      .itemContainer {
        height: calc(100vh - 210px);
        overflow: auto;
      }
    }
    .tabContent.active {
      display: block;
    }
  }
  .header {
    width: 100%;
    background: white;
    border-bottom: 1px solid #E7E7E7;

    button {
      cursor: pointer;
      width: 50%;
      background: transparent;
      border: 0;
      padding: 9px;
      font-weight: 300;
      color: #192233;
      font-size: $font-s;
    }
    .active {
      border-bottom: 2px #365EDE solid;
      font-family: $manrope-semi-bold;
      color: black;
    }

    .disabled {
      color: #192233;
      opacity: 0.4;
    }
  }






}
.modal {
  width: 70% !important;

}
