@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.card-container {
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;
  .card-content {
    position: relative;
    border-radius: 12px;
    border: 1px solid #EBF0F9;
    padding: 15px 12px 15px 20px;
    background-color: $white;
    z-index: 5;
    font-family: $manrope-medium;
    &.shadow {
      box-shadow: 0 4px 14px 0 rgba(0,0,0,0.1);
    }
    .patient-name {
      display: flex;
      align-items: center;
      .text {
        @include text-dots();
        max-width: 180px;
      }
      svg {
        fill: $dark-blue;
        margin-left: -6px;
      }
    }

    .title {
      color: $coral;
      font-size: $font-s;
      margin-bottom: 5px;
    }
    .text {
      font-family: $manrope-medium;
    }

    .content-footer {
      display: flex;
      align-items: center;
      .org-info {
        display: flex;
        align-items: center;
        .logo {
          width: 32px;
          height: 32px;
          border: 1px solid $primary;
          opacity: 0.98;
          background-color: #EAF0FA;
          border-radius: 50%;
          margin-right: 5px;
          //margin-left: -10px;
        }
        .name {
          font-size: $font-xs;
          line-height: 16px;
          @include text-dots(65px)
        }
      }
      .card-number {font-size: $font-xs}
      .stick {
        width: 1px;
        height: 16px;
        background-color: $light-grey;
        margin: 0 10px 0 20px;
      }
      .comments {
        display: flex;
        align-items: center;
        font-size: $font-xs;
      }
    }
  }
  .card-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 20px 10px 20px;
    border-radius: 12px;
    top: -25px;
    color: $dark-blue;
    background-color: $white;
    .left {
      display: flex;
      align-items: center;
      svg {fill: $dark-blue;}
    }
    .right {
      display: flex;
      align-items: center;
      .time {
        padding: 8px 5px;
        margin-right: 10px;
        background-color: rgba(255, 255, 255, .2);
        border-radius: 8px;
        font-size: $font-s;
      }
    }
  }
  .card-footer-neutral {
    //background-image: $grey-gradient;
  }
  .card-footer-green {
    background-image: $green-gradient;
    .left {
      svg {fill: $white}
    }
    .right {
      color: $white
    }
  }
  .card-footer-orange {
    background-image: $orange-gradient;
    color: $white;
    .left {
      svg {fill: $white}
    }
    .right {
      color: $white
    }
  }
  .card-footer-red {
    background-image: $red-gradient;
  }
  .card-footer-blue {
    background-image: $blue-gradient;
    .left {
      svg {fill: $white}
    }
    .right {
      color: $white
    }
  }
  .card-footer-grey {
    background-image: $grey-gradient;
    color: $white;
    .left {
      svg {fill: $white}
    }
    .right {
      color: $white
    }
  }
}

.greenPayed {
  color: #52AA4E;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.orangeNotPayed {
  color: #E7A249;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
