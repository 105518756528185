.section {
  display: flex;
}
.sign-in-wrapper.booking {
  .forms-container {
    .logo {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 234px;
      height: 100px;
    }
  }
  .er-background {
    background-size: cover;
    background-position-x: left;
  }
}

.sign-in-wrapper {
  display: flex;
  height: 100vh;
  .forms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 2;
    .logo {
      margin-top: 100px;
      margin-bottom: 120px;
      width: 234px;
      height: 100px;
    }
  }
  .image-container {
    flex-grow: 2;
    position: relative;
  }

  .background {
    width: 100%;
    height: 100%;
  }
  .er-background {
    background: url("../assets/images/er-background.png") no-repeat center;
    background-size: cover;
  }

  .nurse-background {
    background: url("../assets/images/nurse-background.png") no-repeat center;
    background-size: cover;
  }

  .car-background {
    background: url("../assets/images/car-background.png") no-repeat center;
    background-size: cover;
  }
}

.action-box {justify-content: flex-end}
