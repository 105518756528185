@import "../../assets/styles/variables";

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 40px;
  .filtersWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .selectWrapper {
    flex-basis: 150px;
    max-width: 200px;
  }
}

.loadMoreBtn {
  padding-top: 20px;
  margin: auto;
  button {
    color: $dark-blue;
    font-size: 18px;
  }
}

#historyList, #activeTicketsList, #pendingTicketsList, #rejectedTicketsList {
  .item {
    min-width: 200px;
    box-sizing: unset;
  }
  .statusText {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}




