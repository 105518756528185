@import "../../assets/styles/variables";

#er-dashboard {
  display: flex;
  flex-direction: column;

  .filter-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px 3px 3px 10px;

    &.filter-active {
      color: $white;
      background-color: $dark-blue;
      border-radius: 12px;

      svg {
        fill: $white
      }
    }
  }

  .filter-item {
    font-size: $font-l;
    cursor: pointer;
    padding: 15px 20px 10px 20px;;
    margin: 0 -20px;
    display: flex;
    justify-content: space-between;

    &.active {
      color: $white;
      background-color: #2d4fbc;
    }

    &:hover {
      background-color: #2d4fbc
    }
  }
}
