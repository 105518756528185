// fonts
$manrope-regular: 'Manrope Regular';
$manrope-thin: 'Manrope Thin';
$manrope-light: 'Manrope Light';
$manrope-medium: 'Manrope Medium';
$manrope-semi-bold: 'Manrope Semibold';
$manrope-bold: 'Manrope Bold';
$manrope-roman: 'HelveticaNeuyeCyr-Roman', 'Helvetica', sans-serif;

// font sizes

$font-l: 16px;
$font-main : 14px;
$font-s: 12px;
$font-xs: 11px;

// main colors
$primary: #192233;
$secondary: #0E4A7C;
$dark-blue: #365EDE;
$active: #2D448D;
$column-bg: #F1F6FF;
$grey: #636F77;
$mid-grey: #BDC1CC;
$light-grey: #E7E7E7;
$danger: #D93433;
$white: #ffffff;
$black: #000;
$coral: #505D66;

// gradients

$blue-gradient: linear-gradient(42.46deg, #376ECD 0%, #4CC3CD 100%);
$orange-gradient: linear-gradient(31.73deg, #DF7C2C 0%, #F1CE6B 100%);
$green-gradient: linear-gradient(22.44deg, #3E903C 0%, #67C662 100%);
$red-gradient: linear-gradient(31.73deg, #C94343 0%, #FFA9A9 100%);
$grey-gradient: linear-gradient(25.67deg, #636F77 0%, #C0C2C6 100%);


// status colors



//breakpoints

$break-xsmall: 320px;
$break-small: 375px;
$break-middle: 767px;
$break-middle-laptop: 1024px;
$break-large: 1280px;
$break-x-large: 1440px;

