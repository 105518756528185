@keyframes fade {
  from {
    background: #2D50A7;
  }
}

#loading {
  width: 30px;
  height: 30px;
  top: 11px;
  position: relative;
}

.bubble {
  //background: #2D50A7;
  border-radius: 4px;
  height: 6px;
  margin: -15px 0 0 -4px;
  position: absolute;
  width: 2px;
  -moz-animation-name: fade;
  -moz-animation-duration: 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@for $i from 1 through 8 {
  .bubble:nth-of-type(#{$i + 1}) {
    -moz-transform: rotate(35deg * $i);
    -moz-animation-delay: 0.1s * $i;
    -webkit-transform: rotate(45deg * $i);
    -webkit-animation-delay: 0.1s * $i;
  }
}

.bubble:nth-of-type(1) {
  left: 50%;
  top: 30%;
}

.bubble:nth-of-type(2) {
  left: 70%;
  top: 38%;
}

.bubble:nth-of-type(3) {
  left: 75%;
  top: 55%;
}

.bubble:nth-of-type(4) {
  left: 69%;
  top: 71%;
}

.bubble:nth-of-type(5) {
  left: 50%;
  top: 80%;
}

.bubble:nth-of-type(6) {
  left: 31%;
  top: 72%;
}

.bubble:nth-of-type(7) {
  left: 25%;
  top: 55%;
}

.bubble:nth-of-type(8) {
  left: 30%;
  top: 38%;
}
