@import "../../assets/styles/variables";

.search {
  flex-basis: 300px;

  & > label {
    border-bottom: unset!important;
  }

  input {
    font-size: $font-l !important;
  }
}

.age-input {
  flex-basis: 50px;
}

.select {
  flex-basis: 200px;
  max-width: 250px;
}

.dateRangeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    align-items: center
  }
}
