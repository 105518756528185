@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

#header {
  display: flex;
  flex-direction: column;

  .request-info {
    padding: 20px 20px 25px;
    margin: 0 -40px;

    &.blue-gradient {
      background: $blue-gradient;
      color: $white;

      svg {
        fill: $white
      }
    }

    &.green-gradient {
      background: $blue-gradient;
      color: $white;

      svg {
        fill: $white
      }
    }

    &.orange-gradient {
      background: $orange-gradient;
      color: $white;

      svg {
        fill: $white
      }
    }

    &.grey-gradient {
      background: $grey-gradient;
      color: $white;

      svg {
        fill: $white
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .actions {
        cursor: pointer;
      }
    }

    .close-request-btn {
      margin: -10px -20px!important;
    }

    .status-wrapper {
      .title {
        margin-bottom: 10px
      }

      .text {
        font-family: $manrope-light;
        font-size: 30px;
        line-height: 41px;
      }
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-size: $font-s;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .info-text {
      display: flex;
      align-items: center;

      .text {
        display: flex;
        align-items: center;
        margin-left: 5px;

        &.responsible-person {
          cursor: pointer;
          font-family: $manrope-semi-bold
        }
      }
    }

    .company-name {
      display: flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        border: 1px solid #EAF0FA;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 5px;
      }
    }

    .request-number {
      font-size: $font-l;
      font-family: $manrope-medium;
    }

    #dispatchers-menu {
      max-height: 300px;
      overflow-y: auto;
      p {
        display: flex;
        cursor: pointer;
        align-items: center;
        &.active {
          justify-content: space-between;
          background-color: #2d4fbc;
          svg {fill: $white}
          color: white;
        }
      }
    }
  }

  .panel {
    display: flex;
    background: $white;
    justify-content: space-between;
    padding: 15px 0 0 0;
    font-size: $font-s;
    line-height: 17px;
    cursor: pointer;
    margin: 0 -40px;

    .item {
      flex-basis: 206px;
      text-align: center;

      &.active {
        font-family: $manrope-semi-bold;
        border-bottom: 2px solid $dark-blue;
        padding-bottom: 15px;
      }
    }
  }
}

.close-request-modal {
  .text {
    color: $grey;
    font-size: $font-l;
  }

  .btn-wrapper {
    width: 100%;
  }
}
