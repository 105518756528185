.container {
  width: 100%;
  padding: 24px 32px;
  border-bottom: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;

  .input { width: 350px}
  .inputPrice { width: 120px}

  .content {
    align-self: stretch;
    max-height: 600px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;

    .infoText {
      align-self: stretch;
      color: rgb(99, 111, 119);
      font-size: 12px;
      font-weight: 500;
      overflow-wrap: break-word;
    }
    .title {
      align-self: stretch;
      color: rgb(25, 34, 51);
      font-size: 16px;
      font-weight: 700;
      overflow-wrap: break-word;
    }

    .itemContainer {
      align-self: stretch;
      height: 400px;
      max-height: 800px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      overflow: auto;

      .itemWrapper {
        align-self: stretch;

        padding-top: 24px;
        padding-bottom: 8px;
        padding-left: 12px;
        border-top: 1px dotted rgb(231, 231, 231);
        justify-content: flex-start;
        gap: 16px;
        display: inline-flex;
        .description {
          flex: 1 1 0px;
          color: rgb(25, 34, 51);
          font-size: 14px;
          font-weight: 500;
          overflow-wrap: break-word;
          width: 240px;
        }

        .itemContent {
          flex: 1 1 0px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          display: inline-flex;
        }
      }
    }

  }
}

.footerContainer {
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  border-bottom: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  .footerContent {
    align-self: stretch;
    height: 180px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: flex;
    .footerTitle {
      align-self: stretch;
      color: rgb(25, 34, 51);
      font-size: 16px;
      font-weight: 700;
      overflow-wrap: break-word;
    }

    .footerDescription {
      align-self: stretch;
      color: rgb(99, 111, 119);
      font-size: 12px;
      font-weight: 500;
      overflow-wrap: break-word;

    }
  }
}
