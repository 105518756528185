@import "../../assets/styles/variables";

.tooltipBlue {
  min-width: 220px;
  background-color: $white!important;
  border-radius: 16px!important;
  padding: 20px!important;
  color: $primary!important;
  font-family: $manrope-medium;
  opacity: 1!important;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.1);
  p {
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    margin: 0 -20px;
    &:hover {
      background-color: #2d4fbc;
      color: $white
    }
  }
}

.problemTooltip {
  cursor: pointer;
  .icon {
    position: absolute;
    right: -30px;
    top: -6px;
  }
  .tooltip {
    padding: 4px;
    .text {
      font-size: $font-xs;
    }
  }
}

.searchTooltip {
  max-width: 1180px;
  width: 100%;
  padding: 20px 0!important;
  background-color: $white!important;
  border-radius: 16px!important;
  border: 1px solid $light-grey!important;
  opacity: 1!important;
  color: $primary!important;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.1);
}
