@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

nav {
  width: 100%;
  max-width: 208px;
  min-width: 208px;
  //background-color: $mid-grey;
  transition: max-width 1s;

  .type-icon {
    padding: 7px;
    border-radius: 50%;
    background-color: rgba(25, 34, 51, .4);
    fill: $white;
    margin-bottom: 20px;
  }

  .logo {
    fill: $white;
    margin-bottom: 20px;
  }

  .org-name {
    @include text-dots(180px);
    font-size: $font-l;
    margin-bottom: 20px;
    text-align: center;
    line-height: 20px;
  }

  .create-request {
    margin-bottom: 20px;
    &.minimized {
      padding: 10px 11px;
      button {display: none}
    }
  }

  .divider {
    @include horizontal-line();
    margin-bottom: 40px;
  }

  .wrapper {
    background-color: #2D448D;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    z-index: 100;
    padding: 30px 0 0;
    width: 100%;
    max-width: inherit;
    min-width: 80px;
    scrollbar-width: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
      display: none
    }
  }

  .list-open {
    display: flex;
    flex-direction: column;
    width: 100%;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 10px;

      p {
        margin-left: 10px;
        white-space: nowrap;
        font-family: $manrope-light;
      }
      &.active {
        p {font-family: $manrope-semi-bold}
        .icon {
          background-color: #365EDE;
          & svg #icon {fill: $white}
        }
      }
      .problem-tooltip {
        svg {
          right: 10px;
          top: 3px;
        }
      }
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    padding: 4px;
    position: relative;
    border-radius: 12px;
    display: flex;
    align-items: center;
    & svg #icon {fill: #BDC1CC}
    .icon-calendar {
      width: 40px;
      height: 30px;
    }
  }

  &.navbar-minimized {
    max-width: 84px;
    min-width: 84px;
  }

  .customized-tooltip {
    background-color: #192233;
    padding: 5px;
    font-size: 11px;
    z-index: 10000;
  }
}
