.content-wrapper {
  width: 100%;
  display: flex;
  //margin: 20px
}

.content-header {
  padding: 20px 0 30px 100px;
  font-size: 22px;
}

.data-section {
  //background-color: #AFAFB8;
  margin: 12px;
}
.admin.container {
  width: 100%;
}
.container {
  width: calc(100% - 208px);
  display: flex;
  flex-direction: column;
  //max-width: 1230px;
  //padding: 0 20px 60px 20px;
  //margin: 0 auto;

  &.navbar-minimized {
    width: calc(100% - 84px);
  }
}

.container-small {
  width: 100%;
  max-width: 1000px;
  padding-bottom: 60px;
  margin: 0 auto;
}

.click-disabled {
  pointer-events: none;
}

.d-flex {display: flex!important;}
.flex-row {flex-direction: row}
.flex-column {flex-direction: column}
.flex-wrap {flex-wrap: wrap}
.flex-start {justify-content: flex-start!important;}
.flex-end {justify-content: flex-end!important;}
.flex-center {align-items: center!important;}
.flex-space-around {justify-content: space-around}

.space-between {
  justify-content: space-between;
}

.hidden {
  display: none!important;
}

.overflow-hidden {
  overflow: hidden!important;
}

.request-info-container {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  margin: 0 -40px;
  padding: 0 40px;
  &::-webkit-scrollbar {
    display: none
  }
}

.minButton {
  width: 40% !important;
}

.btn-border-gradient {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  padding: 5px 16px;
  box-sizing: border-box;
  color: $primary;
  background: #fff;
  background-clip: padding-box; /* !importanté */
  border: solid 1px transparent; /* !importanté */
  border-radius: 7px;
  margin-left: 10px;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -1px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: transparent linear-gradient(244deg, #821ADB 0%, #653DDB 47%, #2EA0DC 100%) 0% 0% no-repeat padding-box;
  }
}

.bold {
  font-weight: bold;
}

.text-error {
  color: $danger;
  font-size: 12px;
  font-family: $manrope-regular;
  margin-top: 10px;
}

.text-success {
  color: $secondary;
  font-size: 12px;
  font-family: $manrope-regular;
  margin-top: 10px;
}

.simple-header {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  font-family: $manrope-light;
  background-color: $white;
  z-index: 1;
  padding-top: 50px!important;
  padding-bottom: 50px!important;
  svg {cursor: pointer}
}

.connected-request {
  display: flex;
  align-items: center;
  font-size: $font-s;
  padding: 5px 15px 5px 10px;
  font-family: $manrope-regular;
  border-radius: 10px;
  background-color: $column-bg;
  cursor: pointer;
}



@media (max-width: $break-middle) {
  .hidden-mobile {display: none!important;}
  .container {
    padding: 0 10px 60px 10px
  }
  .content-header {
    font-size: 22px;
    padding: 20px 0 30px 10px;
  }
}

@media (min-width: $break-middle) {
  .hidden-desktop {display: none!important;}
}
