.wrapper {
  display: flex;
  width: 100%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
}

.text {
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.arrow {
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
}

.gray {
  color: grey;
}

.blue {
  color: #365EDE;
}

.green {
  color: #52AA4E;
}

.orange {
  color: #E7A249;
}
