@import 'variables';

// padding classes
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-0 {padding: 0!important;}
.pl-0 {padding-left: 0!important;}
.pr-0 {padding-right: 0!important;}
.pt-0 {padding-top: 0!important;}
.pb-0 {padding-bottom: 0!important;}

.p-5 {padding: 5px!important;}
.pl-5 {padding-left: 5px!important;}
.pr-5 {padding-right: 5px!important;}
.pt-5 {padding-top: 5px!important;}
.pb-5 {padding-bottom: 5px!important;}

.p-10 {padding: 10px!important;}
.pt-10 {padding-top: 10px!important;}
.pr-10 {padding-right: 10px!important;}
.pl-10 {padding-left: 10px!important;}
.pb-10 {padding-bottom: 10px!important;}

.p-15 {padding: 15px!important;}
.pt-15 {padding-top: 15px!important;}
.pr-15 {padding-right: 15px!important;}
.pl-15 {padding-left: 15px!important;}
.pb-15 {padding-bottom: 15px!important;}

.p-20 {padding: 20px!important;}
.pt-20 {padding-top: 20px!important;}
.pr-20 {padding-right: 20px!important;}
.pl-20 {padding-left: 20px!important;}
.pb-20 {padding-bottom: 20px!important;}

.p-25 {padding: 25px!important;}
.pt-25 {padding-top: 25px!important;}
.pr-25 {padding-right: 25px!important;}
.pl-25 {padding-left: 25px!important;}
.pb-25 {padding-bottom: 25px!important;}


.p-40 {padding: 40px!important;}
.pt-40 {padding-top: 40px!important;}
.pr-40 {padding-right: 40px!important;}
.pl-40 {padding-left: 40px!important;}
.pb-40 {padding-bottom: 40px!important;}

.p-40 {padding: 40px!important;}
.pt-40 {padding-top: 40px!important;}
.pr-40 {padding-right: 40px!important;}
.pl-40 {padding-left: 40px!important;}
.pb-40 {padding-bottom: 40px!important;}

.p-70 {padding: 70px!important;}
.pt-70 {padding-top: 70px!important;}
.pr-70 {padding-right: 70px!important;}
.pl-70 {padding-left: 70px!important;}
.pb-70 {padding-bottom: 70px!important;}

// individual padding for panel-content components
.pb-80 {padding-bottom: 80px!important;}

@media (max-width: $break-middle) {
  .p-md-0 {padding: 0!important;}
  .pt-md-0 {padding-top: 0!important;}
  .pr-md-0 {padding-right: 0!important;}
  .pl-md-0 {padding-left: 0!important;}
  .pb-md-0 {padding-bottom: 0!important;}

  .p-md-5 {padding: 5px!important;}
  .pt-md-5 {padding-top: 5px!important;}
  .pr-md-5 {padding-right: 5px!important;}
  .pl-md-5 {padding-left: 5px!important;}
  .pb-md-5 {padding-bottom: 5px!important;}

  .p-md-10 {padding: 10px!important;}
  .pt-md-10 {padding-top: 10px!important;}
  .pr-md-10 {padding-right: 10px!important;}
  .pl-md-10 {padding-left: 10px!important;}
  .pb-md-10 {padding-bottom: 10px!important;}

  .p-md-15 {padding: 15px!important;}
  .pt-md-15 {padding-top: 15px!important;}
  .pr-md-15 {padding-right: 15px!important;}
  .pl-md-15 {padding-left: 15px!important;}
  .pb-md-15 {padding-bottom: 15px!important;}

  .p-md-20 {padding: 20px!important;}
  .pt-md-20 {padding-top: 20px!important;}
  .pr-md-20 {padding-right: 20px!important;}
  .pl-md-20 {padding-left: 20px!important;}
  .pb-md-20 {padding-bottom: 20px!important;}

  .pt-md-30 {padding-top: 30px!important;}
}
