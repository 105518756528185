@import "../../assets/styles/variables";

.rdt {
  display: flex;
  align-self: flex-end;
}

.custom-input {
  max-width: 167px
}

.rdtPicker {
  border-radius: 16px;
  box-shadow: 0 12px 34px 0 rgba(0,0,0,0.25);
  margin-top: 25px!important;
  min-width: 320px;
  margin-left: -50px;
  padding: 20px 30px;
  & thead tr:first-of-type th {
    vertical-align: middle;
    &:hover {border-radius: 10px}
  }
  & td {
    border-radius: 10px;
    vertical-align: middle;
    &.rdtActive {
      background-color: $dark-blue;
    }
  }
}
