.container {
  //display: flex;
  //flex-direction: column;
  //width: 100%;
  //overflow: hidden;
  //border-radius: 8px;
  //background-color: #EA76A4;
  margin-left: 15px;
  box-shadow: 0 8px 20px 0 rgba(68,71,91,0.12);
  //height: 191px;

  width: 100%;
  height: 100%;
  padding: 8px 12px;
  background: white;
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;

.head{
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  .headText {
    flex: 1 1 0px;
    color: rgb(25, 34, 51);
    font-size: 14px;
    font-family: Manrope;
    font-weight: 700;
    overflow-wrap: break-word;
  }
}









}


