@import "../../assets/styles/variables";

.toasterContainer {
  position: absolute;
  top: 100px;
  right: 30px;
  width: 380px;
  font-family: $manrope-medium;
  color: $white;
  z-index: 999;
  border-radius: 16px;
  padding: 15px 25px;
  line-height: 19px;
  &.default {
    background-color: $primary;
  }
  &.green {
    background-color: #3E903C;
  }
  &.red {
    background-color: $danger;
  }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    left: -10px;
    top: -10px;
    padding: 5px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $light-grey;
    &:after {
      content: "";
      background: url("../../assets/svgs/close.svg") no-repeat center;
      background-size: cover;
      width: 20px;
      height: 20px;
      display: flex;
    }
  }
}
