@import "../../assets/styles/variables";

.infoTrigger {
  height: 40px;
  width: 40px;
  border: 1px solid $mid-grey;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
  }
}
