@import "../../assets/styles/variables";

.radio {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid $mid-grey;
  border-radius: 50%;
}

.radio:hover input ~ .checkmark {
  border-color: $grey;
}

.radio input:checked ~ .checkmark {
  background-color: $dark-blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: flex;
}

.radio .checkmark:after {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

.label-text {
  font-size: $font-s;
  font-family: $manrope-regular;
  margin-left: 10px;
}
