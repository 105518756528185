
.toogleContainerInEditMode {
  display: flex;
  align-items: center;
  padding: 8px;
  .darkToogle {
    --unchecked-color: ghostwhite;
    --checked-color: #6C7CF7;
    --shadow: 0 2px 7px 0px #757575;
    --toogle-width: 40px;
    --circle-size: 22px;

    position: relative;
    width: var(--toogle-width);
    user-select: none;
  }
  .label {
    margin-left: 8px;
    //width: 230px;
    text-align: left;

    color: #192233;
  }
  .toogleCheck {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .darkLabel {
    display: block;
    cursor: pointer;
    height: 22px;
    padding: 0;
    line-height: 15px;
    border-radius: 30px;
    transition: 0.5s ease-out;
  }

  .darkLabel:before {
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);

    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    transition: 0.5s ease-out;
    background-size: contain;
    background: #f5f5f5;
  }

  .toogleCheck:not(:checked) + .darkLabel {

    background: linear-gradient(90deg, #636F77 0%, #C0C2C6 100%);
  }

  .toogleCheck:not(:checked) + .darkLabel:before {
    right: 22px;
    box-shadow: var(--shadow);
  }

  .toogleCheck:checked + .darkLabel {
    background: linear-gradient(90deg, #3E903C 0%, #67C662 100%);
    //background-color: var(--checked-color);
  }

  .toogleCheck:checked + .darkLabel:before {
    right: 0;
    box-shadow: var(--shadow);
  }
}

.toogleContainerWithoutEditMode {
  display: flex;
  align-items: center;
  padding: 8px;
  .darkToogle {
    --unchecked-color: #E1E3F3;
    --checked-color: #6C7CF7;
    --shadow: 0 2px 7px 0px #757575;
    --toogle-width: 40px;
    --circle-size: 22px;

    position: relative;
    width: var(--toogle-width);
    user-select: none;
  }
  .label {
    margin-left: 8px;
    //width: 230px;
    text-align: left;
  }
  .toogleCheck {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .darkLabel {
    display: block;
    cursor: pointer;
    height: 22px;
    padding: 0;
    line-height: 15px;
    border-radius: 30px;
    transition: 0.5s ease-out;
  }

  .darkLabel:before {
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);

    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    transition: 0.5s ease-out;
    background-size: contain;
    background: #f5f5f5;
  }

  .toogleCheck:not(:checked) + .darkLabel {
    background: linear-gradient(90deg, #636F77 0%, #C0C2C6 100%);
  }

  .toogleCheck:not(:checked) + .darkLabel:before {
    right: 22px;
    box-shadow: var(--shadow);
  }

  .toogleCheck:checked + .darkLabel {
    background-color: var(--checked-color);
  }

  .toogleCheck:checked + .darkLabel:before {
    right: 0;
    box-shadow: var(--shadow);
  }
}
