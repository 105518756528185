.container {
  padding: 4px;
  background: rgb(241, 246, 255);
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;

  .item {
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 13px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;

    &.selected {
      background: linear-gradient(90deg, rgb(55, 110, 205) 0%, rgb(76, 195, 205) 100%);

      .text {
        color: white;

      }
    }

    .badge {
      width: 16px;
      height: 16px;
      //background: rgb(217, 52, 51);
      border-radius: 16px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      display: flex;
      .badgeCount {
        text-align: right;
        color: white;
        font-size: 10px;
        font-weight: 700;
        overflow-wrap: break-word;
      }
    }

    .text {
      text-align: center;
      color: rgb(25, 34, 51);
      font-size: 16px;
      font-weight: 600;
      overflow-wrap: break-word;
    }
  }
}
