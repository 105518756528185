.container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(231, 231, 231);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;

  .head {
    align-self: stretch;
    height: 48px;
    //padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    display: flex;

    .headWrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      .title {
        flex: 1 1 0px;
        color: rgb(25, 34, 51);
        font-size: 16px;
        font-weight: 700;
        overflow-wrap: break-word;
      }
    }
  }

  .body {
    //align-self: stretch;
    ////height: 448px;
    ////padding-left: 24px;
    ////padding-right: 24px;
    //flex-direction: column;
    //justify-content: flex-start;
    //align-items: flex-start;
    //gap: 24px;
    //display: flex;

    display: flex;
    flex-wrap: wrap; /* Ensures items wrap when space is limited */
    justify-content: space-between;
    flex-direction: column;


    .wrapper {
      flex: 1 1 60px; /* Adjusts based on screen size */
      min-width: 250px; /* Ensures a minimum width for each item */
      margin-bottom: 15px; /* Adds space between rows */
      display: flex;
      justify-content: space-between; /* Aligns content within the wrapper */

      .dcText {
        min-width: 210px;
        p {
          max-width: 100px;
        }
      }
      .addressInfo p {
        max-width: 300px;
      }

      .city p, .subway p {
        max-width: 100px;
      }
    }

  }
}
