@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

#patientInfo {
  position: relative;

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .city p, .subway p {
      @include text-dots();
      max-width: 100px;
    }

    .addressInfo p {
      @include text-dots();
      max-width: 300px;
    }
  }

  .divider {
    @include horizontal-line()
  }

  .editIcon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
  }
}

#offersContainer {
  border-bottom: 1px solid $light-grey;
  margin: 0 -40px 20px -40px;
  padding: 0 40px 20px 40px;

  .offerCard {
    border: 1px solid $light-grey;
    padding: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0
    }

    &.active {
      border-color: $mid-grey;
    }

    &.approved {
      background-color: $column-bg;
      border-color: $dark-blue
    }

    &.disabled {
      border-color: #C2D1D9;
      color: #C2D1D9;

      .infoText span {
        color: #C2D1D9;
      }

      .time {
        background-color: $mid-grey;
        color: $coral;
      }
    }
  }

  .loader {
    width: 20px;
    height: 20px;
  }

  .radio {

  }

  .infoWrapper {
    flex-grow: 1;
    margin-left: 15px;

    .textMain {
      font-size: $font-l;
      font-family: $manrope-medium;
      padding-bottom: 10px;
    }

    .wrapper {
      display: flex;

      .infoText {
        span {
          padding-bottom: 0;
        }

        &:last-child {
          margin-left: 50px;
        }
      }
    }
  }

  .time {
    padding: 9px 8px;
    background-color: $dark-blue;
    border-radius: 8px;
    font-size: $font-s;
    color: $white;
    flex-basis: 60px;
    align-self: flex-end;
    text-align: center;
  }
}

#brigadeInfo {
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-basis: 167px;
    }
  }
}

#diagnoseInfo {
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  &.hospitalization {
    padding: 20px;
    margin: 0;
    background-color: $column-bg;
  }

  .wrapper {
    .info p {
      font-size: $font-l;
      font-family: $manrope-regular;
    }
  }

  .hospitalButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    Button {
      width: 250px;

    }
  }
}

.brigadeInfo {
  position: relative;
  margin-top: 20px;
  padding: 0 25px 35px 25px;
  border-bottom: 1px solid $light-grey;
  justify-content: space-between;

  .trashIcon, .editIcon {
    position: absolute;
    top: 0;
    right: 50px;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;

  }
}
#hospitalInfo {
  margin: 0 -40px 25px -40px;
  padding: 0 40px 35px 40px;
  border-bottom: 1px solid $light-grey;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wrapper {
      .info p {
        font-size: $font-l;
        font-family: $manrope-regular;
      }
    }
  }
}

#hospitalizationForm {
  .wrapper {
    display: flex;
    margin-bottom: 30px;

    .input {
      flex-basis: 200px;
      max-width: 200px;
      flex-grow: 1;

      &:not(:first-child) {
        margin-left: 20px
      }
    }
  }
}

.input1 {
  margin-bottom: 24px;
}

#hospitalsList {
  padding-bottom: 140px;
  .header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $light-grey;
    padding: 0 40px;
    margin: 0 -40px;

    .checkbox {
      margin-bottom: 20px;
      span {
        font-family: $manrope-bold;
      }
    }

    .input {
      max-width: 350px;
      width: 100%;
      margin-bottom: 20px;
      label {
        border-bottom: unset;
      }

      input {
        font-size: 20px;
      }
    }
  }

  .list {
    width: 100%;

    .item {
      cursor: pointer;
      display: flex;
      border-bottom: 1px solid $light-grey;
      padding: 15px 40px;
      margin: 0 -40px;

      .checkbox {
        width: 100%;
        line-height: 18px;
      }

      &.active {
        background-color: $column-bg;
      }
    }

    .name {
      font-family: $manrope-semi-bold;
      margin-bottom: 5px;
      max-width: 500px;
    }
  }
}

#bookingOffers {
  margin-top: -24px;

  .item {
    margin-bottom: 15px;
  }

  .header {
    display: flex;
    padding: 15px 40px;
    margin: 0 -40px 10px -40px;
    background-color: $column-bg;
    cursor: pointer;

    p {
      font-size: $font-l;
      font-family: $manrope-medium;
      margin-left: 15px;
      color: #192233;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-transform: capitalize;
    }
  }

  .wrapper {
    display: flex;
    margin-bottom: 20px;

    div {
      flex-grow: 1;
      flex-basis: 200px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: $font-l;
  font-family: $manrope-semi-bold;

  svg {
    fill: $dark-blue;
    margin-right: 10px
  }

  margin: 0 0 20px -6px;
}
