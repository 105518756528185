@import "../../../assets/styles/default-form";

.flex-basis {
  flex-basis: 120px;
}

.input-disabled input {
  cursor: not-allowed;
  color: $grey !important;
  pointer-events:none;
}
