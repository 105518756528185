@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

header {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid $light-grey;
  padding: 0 30px;
  z-index: 500;
  background-color: $white;

  &.admin{
    background: $white;
  }

  .left {
    display: flex;
    align-items: center;

    .burger-menu {
      cursor: pointer;
    }

    .page-title {
      font-weight: 400;
      margin-left: 15px;
    }

    .city {
      font-size: 24px;
      font-family: $manrope-light;
      margin-left: 20px;
      //align-self: flex-end;
      //line-height: 19px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        width: 30px;
        height: 25px;
      }
    }

    .city-item {
      font-size: $font-l;
    }

    .date {
      font-size: $font-l;
      text-transform: capitalize;
      line-height: 19px;
      margin-left: 60px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .search-input {
    }

    .search-icon {
      cursor: pointer;
      fill: $primary
    }

    .user-name {
      margin-right: 12px;
      font-family: $manrope-semi-bold
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
    }

    .menu-list {
      li {
        font-size: $font-l;
        margin-bottom: 20px;
        padding: 10px 20px 10px 20px;
        margin: 0 -20px;
        cursor: pointer;
        &:hover {
          background-color: #2d4fbc;
          a {
            color: $white
          }
        }
        &:last-child {margin-bottom: 0}
      }
      .line {
        @include horizontal-line(unset);
        margin: 0 -20px 20px;
        opacity: .2;
      }
    }
  }
}
