@import "../../assets/styles/variables";

.checkbox {
  display: flex;
  align-items: center;
  vertical-align: top;
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;
  width: max-content;
  height: min-content;
  cursor: pointer;

  &.top {
    flex-direction: column-reverse;
    justify-content: center;

    .label-text {
      margin-bottom: 10px;
      margin-left: 0
    }
  }

  &.right {
    flex-direction: row-reverse;

    .label-text {
      margin-right: 10px;
      margin-left: 0
    }
  }

  &.bottom {
    flex-direction: column;
    justify-content: center;

    .label-text {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  &.small {
    input {
      &:checked ~ .checkbox-icon:before {
        width: 16px;
        height: 16px;
      }
    }
    .checkbox-icon {
      width: 16px;
      height: 16px;
    }

    .label-text {
      font-size: 14px;
    }
  }

  &.error {
    .label-text {color: $danger}
  }

  input {
    display: none;

    &:checked ~ .checkbox-icon:before {
      content: "";
      background-repeat: no-repeat;
      background-image: url(./check-icon.svg);
      background-color: $dark-blue;
      background-position: center;
      background-size: 8px;
      font-weight: lighter;
      text-transform: none;
      height: 14px;
      width: 14px;
      display: inline-block;
      border-radius: 4px;
    }

    &:checked ~ .checkbox-icon {
      border: unset
    }

    &:hover:not(:checked) ~ .checkbox-icon {
      border: 1px solid $grey
    }
  }



  .checkbox-icon {
    width: 14px;
    height: 14px;
    font-size: 9px;
    text-align: center;
    color: $primary;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid $mid-grey;
  }

  .label-text {
    font-size: $font-s;
    font-family: $manrope-regular;
    margin-left: 10px;
  }
}

//.settings-card-body {
//  .checkbox-icon {
//    border: 1px solid #999999;
//    &:hover {
//      border: 1px solid #AFAFB8;
//    }
//  }
//}
