@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.booking {
  .form-container {
    width: 540px;
    overflow-x: auto;
    .scrollable {
      max-height: 500px;
      overflow-x: auto;
      .wrapper {
        .select-wrapper.department {
          width: 100%;
          flex-basis: auto;
        }
      }
    }
  }
}

.form-container {
  width: 472px;
  max-width: 100%;

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .select-wrapper {
      flex-basis: 167px;
      &.select-small {
        flex-basis: 73px;
      }
    }
  }

  #textarea-container .label {
    color: $coral;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
  }

  #textarea-container .textarea {
    max-height: 34px;
    width: 100%;
    color: $primary;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
  }

  .title {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .description {
    font-size: $font-l;
    margin-bottom: 40px;
    line-height: 22px;
  }

  .sub-description {
    font-size: $font-main;
    margin-bottom: 40px;
    line-height: 22px;
  }

  .button {
    width: 242px;
    max-width: 100%;
    font-family: $manrope-bold;
    padding: 11px 18px;
    justify-content: center;
  }
}

#login-container {
  .title {margin-bottom: 60px}
  .forgot-password-link {
    cursor: pointer;
    color: $dark-blue;
    font-size: $font-s;
  }
}

.divider {
  @include horizontal-line()
}


