@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.head {
  align-items: center;
  margin-bottom: 20px;
  h3 {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 2px;
    margin-left: 25px;
    text-transform: uppercase;
  }
  .back {
    cursor: pointer;
  }
}

.organization-container {
  background-color: #F1F6FF;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 30px;

  .left {
    align-items: center;
    .logo {
      width: 78px;
      height: 78px;
      background-color: $primary;
      border-radius: 4px;
    }
    p {
      margin: 0 50px;
      line-height: 24px;
      text-align: center;
    }
  }
  .separator {
    border-left: 1px solid $mid-grey;
    margin-right: 30px
  }
  .right {
    justify-content: space-around;
    p {
      display: flex;
      align-items: center;
      margin-right: 40px;
      svg {
        margin-right: 20px;
      }
    }
  }
}
