.container {
  cursor: pointer;
  width: auto;
  display: block;
  margin-bottom: 12px;
  margin-top: 12px;


  :global(.BrainhubCarousel__arrow--disable) {
    svg {
      fill: rgba(0, 0, 0, 0.4);
    }
  }
  :global(.BrainhubCarousel__container) {
    overflow: visible;
  }
  :global(.BrainhubCarousel) {
    overflow: visible;
    position: relative;
  }
  :global(.BrainhubCarouselItem) {
    align-self: flex-start;
  }
  :global(.BrainhubCarousel__custom-arrowLeft) {
    position: absolute;
    left: 20px;
    //border-radius: 100%;
    z-index: 2;

    background-color: white;
    border-radius: 8px;
    border: 1px #BDC1CC solid;
    width: 20px;
    height: 20px;
    cursor: pointer;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    padding: 16px;
    svg {
      border: 1px solid #e3e7f8;
      box-shadow: none;
      padding: 10px;
    }
  }
  :global(.BrainhubCarousel__custom-arrowRight) {
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    //border-radius: 100%;
    z-index: 2;
    background-color: white;
    border-radius: 8px;
    border: 1px #BDC1CC solid;
    width: 20px;
    height: 20px;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    svg {
      border: 1px solid #e3e7f8;
      box-shadow: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #35363f;
    }
  }
  .carusel {
    background: transparent;
  }
}

.title {
  width: 270px;
  font-size: 14px;
}

.icon {
  fill: #a1a5c5;
  width: 27px;
  height: 27px;
  margin-right: 10px;
}

.moreContent {
  display: flex;
  flex-direction: column;
  min-width: 75px;
  > button {
    text-align: left;
    user-select: none;
    border: 0;
    background: #fff;
    padding: 10px;
  }
}
