@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.temp-03-form {
  padding-bottom: 50px;
  margin-top: 40px;
  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    #input-container {
      flex-basis: 167px;
      &.input-large {
        flex-basis: 100%;
      }
      &.input-small {
        flex-basis: 73px
      }
    }
    #input-container.bold {
      input {
        font-weight: bold;
      }
    }
    .select-wrapper {
      flex-basis: 167px;
      &.select-large {
        flex-basis: 100%;
      }
      &.select-small {
        flex-basis: 80px;
      }
    }
  }
}

.form-wrapper {
  display: flex;
  //justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.type {max-width: 167px}

.edit-btn {
  width: 100%;
  button {margin: auto}
}
