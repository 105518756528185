@import '../../assets/styles/variables';

#textarea-container {
  .no-border {
    border: 0 !important;
    outline: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .textarea {
    border-radius: 6px;
    outline: 0;
    border: 1px solid $mid-grey;
    padding: 10px;
    font-family: $manrope-regular;
    resize: none;
    font-size: 14px;
    &.error {
      border-color: $danger;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: $manrope-light;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-family: $manrope-light;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-family: $manrope-light;
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-family: $manrope-light;
    }
    &:focus {
      border-color: $primary
    }
  }
  .label {
    margin-bottom: 5px;
  }
}

