@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.divider {
  @include horizontal-line()
}

#patient-info {
  position: relative;
}

#offer-request {
  .footer {
    justify-content: center;
    .offer-button {
      width: 100%;
      button {margin: auto}
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .select-wrapper {
      flex-basis: 120px;
    }
  }
}

#closed-request {
  &.negative-margin {margin-top: -24px}
  .wrapper {
    background-color: $column-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 30px;

    .info-wrapper {
      p {
        font-family: $manrope-semi-bold;
        line-height: 19px;
        margin-bottom: 5px;
      }

      span {
        font-size: $font-s;
        font-family: $manrope-light;
        color: $dark-blue;
        cursor: pointer
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .hospitalization-popover {
    overflow-y: visible;
  }
  .booking-offers {
    padding: 20px 40px 20px 40px;
    margin: 0 -40px;
    background-color: $column-bg;
    .text {
      svg {
        fill: $dark-blue;
        margin-right: 10px
      }
      display: flex;
      align-items: center;
      font-size: $font-l;
      font-family: $manrope-semi-bold;
    }
    span {
      font-size: 12px;
      color: #365ede;
      cursor: pointer;
    }
  }
}

.comments-footer {
  position: absolute!important;
  width: 100%;
  border-bottom: unset;
  #input-container {
    width: 100%;
    .input-wrapper {
      border-bottom: unset;
    }
  }
  .upload-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    input {display: none}
  }
}

.hospitals-list-panel-title {
  display: flex;
  align-items: center;
  svg {margin-right: 10px}
}
